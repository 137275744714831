import React from 'react';

const ContactInfo = ({handlerDarkmode,darkMode}) => {
  return (
    <div id="contactme" className={darkMode?"bg-indigo-950 p-12 mx-auto":"bg-white p-12 mx-auto"}>
            <a href="https://www.linkedin.com/in/alexandrecruz1/" target="_blank" rel="noopener noreferrer" className={darkMode ? "bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2" : "bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"}>
        LinkedIn
      </a>
      <a href="https://github.com/alexandrecruzdev" target="_blank" rel="noopener noreferrer" className={darkMode ? "bg-gray-800 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2" : "bg-gray-900 hover:bg-gray-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"}>
        GitHub
      </a>
      <a href="https://api.whatsapp.com/send?phone=5521996924975" target="_blank" rel="noopener noreferrer" className={darkMode ? "bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" : "bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"}>
        WhatsApp
      </a>

    </div>
  );
};

export default ContactInfo;
