
import './App.css';
import React, { useState } from 'react';
import { Navbar } from './components/Navbar';
import Presentation from './components/Presentation';
import Projects from './components/Projects';
import Skills from './components/Skills';
import ContactInfo from './components/ContactInfo';
import Sobre from './components/Sobre'
function App() {

  const [darkMode, setDarkMode] = useState(true);

  const handlerDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className="App">
      <Navbar handlerDarkMode={handlerDarkMode} darkMode={darkMode}/>
      <Presentation handlerDarkMode={handlerDarkMode} darkMode={darkMode} />
      <Projects handlerDarkMode={handlerDarkMode} darkMode={darkMode} />
      <Skills handlerDarkMode={handlerDarkMode} darkMode={darkMode} />
      <Sobre handlerDarkMode={handlerDarkMode} darkMode={darkMode} />
      <ContactInfo handlerDarkMode={handlerDarkMode} darkMode={darkMode} />

    </div>
  );
}
export default App;
