import React from 'react';
import SpaceAdventureImg from '../images/projects/imagem_spaceAdventure.png'
import CommerceCs50Img from '../images/projects/commercecs50.png'
import MailCs50Img from '../images/projects/mailcs50.png'
import NetworkCs50Img from '../images/projects/networkcs50.png'
import WikiCs50Img from '../images/projects/wikics50.png'
import GerenciadorImg from '../images/projects/gerenciador_mobile.png'
const projects = [
  {
    id: 1,
    title: 'Space Adventure',
    description: 'O "Space Adventure" leva os jogadores a uma jornada emocionante por mundos exóticos e perigosos. Com uma mistura de aventura e plataforma, o jogo desafia os jogadores a enfrentar obstáculos, inimigos e perigos enquanto exploram cenários intrigantes.',
    link: 'https://github.com/alexandrecruzdev/SpaceAdventure_Game2D_Unity',
    demoVideo: 'https://www.youtube.com/watch?v=4Ta6EJXTeos',
    imageUrl: SpaceAdventureImg,
  },
  {
    id: 2,
    title: 'Site de Leilões Online',
    description: 'Este projeto consiste na conclusão da implementação de um site de leilões online, fornecendo aos usuários uma plataforma para criar, visualizar e participar de leilões de uma ampla variedade de itens. O projeto é desenvolvido utilizando Django para o backend e HTML, CSS e JavaScript para o frontend.',
    link: 'https://github.com/alexandrecruzdev/commercecs50',
    demoVideo: 'https://youtu.be/p_T3jNdH5yQ',
    imageUrl: CommerceCs50Img,
  },
  {
    id: 3,
    title: 'Rede Social Interativa',
    description: 'Este projeto consiste na implementação de uma rede social interativa utilizando Python, JavaScript, HTML e CSS. A plataforma permite aos usuários criar postagens, seguir outros usuários, interagir por meio de curtidas e realizar operações como editar suas próprias postagens.',
    link: 'https://github.com/alexandrecruzdev/networkcs50',
    demoVideo: 'https://www.youtube.com/watch?v=SirCk41M73E',
    imageUrl: NetworkCs50Img,
  },
  {
    id: 4,
    title: 'Cliente de E-mail de Aplicativo de Página Única',
    description: 'Este projeto consiste na implementação de um cliente de e-mail usando JavaScript, HTML e CSS, com a principal funcionalidade de permitir que os usuários gerenciem suas caixas de entrada, enviem e-mails, visualizem e respondam a mensagens, além de arquivarem e desarquivarem e-mails.',
    link: 'https://github.com/alexandrecruzdev/mailcs50',
    demoVideo: 'https://youtu.be/afHRokiyVSk',
    imageUrl: MailCs50Img,
  },
  {
    id: 5,
    title: ' Enciclopédia Wiki',
    description: 'O projeto da Enciclopédia Wiki é uma plataforma web que permite aos usuários explorarem e contribuírem com conteúdo sobre uma variedade de tópicos. Com funcionalidades de visualização, pesquisa, criação e edição de entradas, os usuários têm a liberdade de navegar pela enciclopédia, buscar informações específicas e colaborar na expansão do conhecimento disponível.',
    link: 'https://github.com/alexandrecruzdev/wikics50',
    demoVideo: 'https://youtu.be/UMMp6tzJjdQ',
    imageUrl: WikiCs50Img,
  },
  {
    id: 6,
    title: ' Gerenciador de Tarefas e Compromissos Mobile',
    description: ' Gerenciador de Tarefas e Compromissos, um aplicativo desenvolvido em C# utilizando .NET MAUI e SQLite. Este aplicativo permite aos usuários gerenciar suas tarefas diárias e compromissos de forma eficiente e organizada.',
    link: 'https://github.com/alexandrecruzdev/gerenciador_tarefa_compromisso',
    demoVideo: 'https://www.youtube.com/watch?v=s0TN-miprls',
    imageUrl: GerenciadorImg,
  }
];

const Projects = ({ handlerDarkMode, darkMode }) => {
  return (
    <div id="projects" className={darkMode ? "bg-indigo-950 text-gray-100 p-10" : "bg-white text-gray-800 p-10"}>
      <div className="max-w-5xl mx-auto">
        <h1 className="text-3xl font-semibold mb-8">Meus Projetos</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map(project => (
            <div key={project.id} className={`bg-gray-100 rounded-lg overflow-hidden shadow-md ${darkMode ? "text-gray-900" : "text-gray-800"}`}>
              <img src={project.imageUrl} alt={project.title} className="w-full h-40 object-cover object-center" />
              <div className="p-6">
                <h2 className="text-xl font-semibold mb-2">{project.title}</h2>
                <p className="mb-4">{project.description}</p>
                <a href={project.link} target="_blank" rel="noopener noreferrer" className={darkMode ? "block bg-indigo-900 hover:bg-indigo-950 text-white font-semibold py-2 px-4 rounded mb-2 " : "block bg-indigo-950 hover:bg-indigo-900 text-white font-semibold py-2 px-4 rounded mb-2" }>
                  Ver Projeto
                </a>
                <a href={project.demoVideo} target="_blank" rel="noopener noreferrer" className={darkMode ? "block bg-indigo-900 hover:bg-indigo-950 text-white font-semibold py-2 px-4 rounded mb-2 " : "block bg-indigo-950 hover:bg-indigo-900 text-white font-semibold py-2 px-4 rounded mb-2"}>
                  Ver Demo
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
