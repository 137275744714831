import React from 'react';

const skills = [
  "HTML",
  "CSS",
  "JavaScript",
  "React",
  "Tailwindcss",
  "Node.js",
  "DjangoFramework",
  "Python",
  "Postgresql",
  "SQL",
  "Git",
  "Github",
  "Unity Engine",
  "C#",
  ".NET MAUI"

  // Adicione mais habilidades conforme necessário
];

const Skills = ({handlerDarkMode,darkMode}) => {
  return (
    <div id="skills"className={darkMode ? "bg-indigo-950  text-gray-800  p-10":"bg-white  text-gray-800  p-10"}>
    <div className='max-w-3xl mx-auto mt-10'>
    <h1 className={darkMode? "text-white text-3xl font-semibold mb-4":"text-3xl font-semibold mb-4"}>Minhas Habilidades</h1>
      <ul className="grid grid-cols-2 sm:grid-cols-3 gap-4">
        {skills.map(skill => (
          <li key={skill} className={darkMode?"bg-indigo-900  p-4 rounded-lg shadow-md text-center text-white":"bg-indigo-950  p-4 rounded-lg shadow-md text-center text-white"}>{skill}</li>
        ))}
      </ul>
    </div>
    </div>
  );
};

export default Skills;
