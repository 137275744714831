import React from 'react';

const Sobre = ({ handlerDarkMode, darkMode }) => {
    return (
        <div id="sobre" className={`${darkMode ? "bg-indigo-950" : "bg-white"} text-gray-800 p-10 mx-auto shadow-md`}>
            <div className="max-w-3xl mx-auto">
                <h2 className={`${darkMode ? "text-white" : "text-gray-800"} text-3xl font-semibold mb-4 text-center`}>Sobre</h2>
                <p className={`${darkMode ? "text-white" : "text-gray-800"} text-lg mb-6 leading-relaxed`}>
                Entusiasta da tecnologia, atualmente buscando oportunidades de estágio ou posição
júnior para aplicar minha paixão por desenvolvimento de software. Possuo
experiência em suporte de TI e desenvolvimento full-stack, com habilidades sólidas
em Python, Django Framework, PostgreSQL, JavaScript, HTML, CSS, SQL, Git, GitHub,
C# e Unity. Tenho capacidade comprovada de adaptação e aprendizado rápido,
sempre em busca de aprimorar minhas habilidades técnicas.
Sou motivado, apaixonado por tecnologia e estou sempre em busca de novos
desafios que me permitam crescer profissionalmente.

                </p>
            </div>
        </div>
    );
};

export default Sobre;
