import React from 'react';
import Avatar from '../images/avatar.png'

const Presentation = ({ handlerDarkMode, darkMode }) => {
  const downloadResume = () => {
    // Lógica para baixar o currículo
    const pdfUrl = process.env.PUBLIC_URL + '/curriculo.pdf'; // Substitua 'seu_arquivo.pdf' pelo nome do seu PDF

    // Crie um link <a> para fazer o download do PDF
    const a = document.createElement('a');
    a.href = pdfUrl;
    a.download = 'curriculo.pdf'; // Nome do arquivo PDF
    document.body.appendChild(a);

    // Dispare um clique no link para iniciar o download
    a.click();
  };

  return (
    <div id="presentation" className={darkMode ? "bg-indigo-950 text-gray-800 p-12 mx-auto" : "bg-white text-gray-800 p-10 mx-auto"}>
      <div className="flex items-center justify-center">
        <img src={Avatar} className="rounded-lg w-64 h-64" alt="Avatar" />
      </div>
      <h1 className={darkMode ? "text-white text-3xl font-semibold mb-4" : "text-3xl font-semibold mb-4"}>Bem-vindo ao meu Portfólio</h1>
      <p className={darkMode ? "text-white mb-6" : "mb-6"}>Obrigado por visitar meu portfólio. Aqui você pode encontrar informações sobre mim e meus projetos.</p>
      <a
        onClick={downloadResume}
        download=""
        className={darkMode ? "bg-indigo-900 hover:bg-indigo-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2" : "bg-indigo-950 hover:bg-indigo-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"}
      >
        Baixar Currículo
      </a>

    </div>
  );
};

export default Presentation;
