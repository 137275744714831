import { IoMdSunny, IoMdMoon } from 'react-icons/io'; // Importando ícones de sol e lua
import {Link , animateScroll as scroll} from 'react-scroll'
export const Navbar = ({ handlerDarkMode, darkMode }) => {
    return (
        <nav className={darkMode ?"bg-indigo-950 shadow-lg sticky top-0 w-full " : "bg-white shadow-lg sticky top-0 w-full " }>
            <div className="max-w-7xl mx-auto px-4">
                <div className="flex justify-between">
                    <div className="flex items-center py-4">
                        <div className={darkMode ? "bg-white py-2 px-4 rounded-md flex items-center m-1 focus:outline-none":"bg-indigo-950 py-2 px-4 rounded-md flex items-center m-1 focus:outline-none"}><h1 className={darkMode?'text-indigo-900 font-bold text-lg':'text-white font-semibold text-lg'}>A</h1></div>
                        <h1 className={darkMode? "text-white font-semibold text-lg":"text-indigo-950 font-semibold text-lg"}>CRUZ</h1>
                    </div>
                    <div className="hidden md:flex items-center space-x-4">
                        <Link  smooth={true} to="presentation" className={darkMode ?"text-gray-200 hover:text-white px-3 py-2 rounded-md text-sm font-medium":"text-indigo-950 hover:text-indigo-700 px-3 py-2 rounded-md text-sm font-medium" }>Home</Link>
                        <Link  smooth={true} to="projects" className={darkMode ?"text-gray-200 hover:text-white px-3 py-2 rounded-md text-sm font-medium":"text-indigo-950 hover:text-indigo-700 px-3 py-2 rounded-md text-sm font-medium" }>Projetos</Link>
                        <Link  smooth={true} to="skills" className={darkMode ?"text-gray-200 hover:text-white px-3 py-2 rounded-md text-sm font-medium":"text-indigo-950 hover:text-indigo-700 px-3 py-2 rounded-md text-sm font-medium" }>Habilidades</Link>
                        <Link  smooth={true} to="sobre" className={darkMode ?"text-gray-200 hover:text-white px-3 py-2 rounded-md text-sm font-medium":"text-indigo-950 hover:text-indigo-700 px-3 py-2 rounded-md text-sm font-medium" }>Sobre</Link>                   
                        <Link  smooth={true} to="contactme" className={darkMode ?"text-gray-200 hover:text-white px-3 py-2 rounded-md text-sm font-medium":"text-indigo-950 hover:text-indigo-700 px-3 py-2 rounded-md text-sm font-medium" }>Contate-me</Link>                   
                        <button
                            className={darkMode ? "bg-indigo-900 hover:bg-indigo-800 text-white py-2 px-4 rounded-full flex items-center focus:outline-none":"bg-indigo-950 hover:bg-indigo-900 text-white py-2 px-4 rounded-full flex items-center focus:outline-none"}
                            onClick={handlerDarkMode}
                        >
                            {darkMode ? <IoMdSunny className="h-6 w-6 mr-1" /> : <IoMdMoon className="h-6 w-6 mr-1" />}

                        </button>
                    </div>
                </div>
            </div>
        </nav>
    );
}